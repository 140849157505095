import { Quotebox as StandaloneQuotebox } from '@/components/Quotebox';
import { StandaloneArticleTeaser } from 'base/components/ArticleTeaser';
import { GenericSlot } from 'base/components/GenericSlot';
import HantArticleTeaserTheme from './theme';

const Sticker = GenericSlot({ as: StandaloneQuotebox, theme: HantArticleTeaserTheme.embed.sticker });

export const HantStandaloneArticleTeaser: typeof StandaloneArticleTeaser = (props) => {
  return (
    <StandaloneArticleTeaser
      {...props}
      sticker={props.sticker ? <Sticker quote={props.sticker} quotationMark={''} /> : undefined}
    />
  );
};
